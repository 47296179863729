/* eslint-disable import/exports-last */
import { graphql, navigate } from 'gatsby';

import DynamicZone from '../components/dynamic-zone/dynamic-zone';
import FloatingButton from '../components/floating-button/floating-button';
import Layout from '../components/layout';
import React from 'react';

export const query = graphql`
query ($slug: String!, $locale: String!){
  cms {
    pages (where: {slug: $slug}, locale: $locale) {
      slug
      navigateToForm
      components {
        ... on STRAPI_ComponentSeoSeo {
          __typename
          seoDescription
          seoImages {
            url
          }
          seoLink
          seoTitle
        }
        ... on STRAPI_ComponentUseCasesUseCasesSection {
          __typename
          topPusherHeightMobile
          topPusherHeight
          sectionTitle {
            ytLink
            titleIcon {
              url
            }
            title
            style
            tailwindClasses
            description
          }
          quotationSection {
            authorCompany
            authorImage {
              url
            }
            authorName
            authorRole
            quoatationIcon {
              url
            }
            quotation
          }
          listBullet {
            url
          }
          UseCasesColumns {
            title
            subtitle
            icon {
              url
            }
            backgroundImage {
              url
            }
          }
        }
        ... on STRAPI_ComponentVerticalTabsVerticalTabs {
          __typename
          workOffer
          topPusherHeightMobile
          topPusherHeight
          sectionTitle {
            ytLink
            titleIcon {
              url
            }
            title
            tailwindClasses
            style
            description
          }
          VerticalTabTitle {
            title
            image {
              url
            }
          }
          VerticalTabContent {
            titleImage {
              url
            }
            title
            contentTexts
            contentRichText
            contentImages {
              url
            }
          }
        }
        ... on STRAPI_ComponentHorizontalTabsHorizontalTabs {
          __typename
          topPusherHeightMobile
          topPusherHeight
          tabsDarkBackground
          tabsContentColor
          tabs {
            type
            title
            subtitle
            sendButtonLabel
            scrollColor
            lottieData
            link
            labelImage {
              url
            }
            labelColour
            label
            isLabelImageVisibleOnDesktop
            imagesTitles
            imagesTailwindClasses
            imagesMetadata
            images {
              url
            }
            fullWidthImageItemsDirection
            formFieldsData
            brochuresToDownload {
              url
            }
          }
          tabHeight
          sectionTitle {
            ytLink
            titleIcon {
              url
            }
            title
            tailwindClasses
            style
            description
          }
          middleImage {
            url
          }
        }
        ... on STRAPI_ComponentComponentsHorizontalScroll {
          __typename
          topPusherHeightMobile
          topPusherHeight
          sectionTitle {
            ytLink
            titleIcon {
              url
            }
            title
            tailwindClasses
            style
            description
          }
          mobileCardTailwindClasses
          imagesTailwindClasses
          horizontalScrollColor
          hasMobileGrayBackground
          HorizontalScrollCaseStudy {
            case_study {
              logo {
                url
              }
              link
            }
          }
          HorizontalScrollBenefits {
            subtitle
            title
            image {
              url
            }
          }
        }
        ... on STRAPI_ComponentComponentsImagesWithOffset {
          __typename
          topPusherHeightMobile
          topPusherHeight
          sectionTitle {
            ytLink
            titleIcon {
              url
            }
            title
            tailwindClasses
            style
            description
          }
          offsetComponentType
          imagesWithOffsetScrollColor
          contentOffset
          bottomText
          content {
            subtitle
            title
            image {
              url
            }
          }
        }
        ... on STRAPI_ComponentComponentsMoneyLicenses {
          __typename
          topPusherHeightMobile
          topPusherHeight
          moneyLicence {
            titleLink
            title
            image {
              url
            }
            content
          }
        }
        ... on STRAPI_ComponentComponentsBenefits {
          __typename
          Benefit {
            image {
              url
            }
            subtitle
            title
          }
          hasDarkBackground
          imagesTailwindClasses
          itemsToLeft
          mobileItemsToLeft
          benefitsScrollColor
          topPusherHeight
          topPusherHeightMobile
          sectionTitle {
            ytLink
            titleIcon {
              url
            }
            title
            tailwindClasses
            style
            description
          }
        }
        ... on STRAPI_ComponentComponentsTextWithLinkAndChart {
          __typename
          textWithLinkAndChartContent
          link
          lottieChart
          textWithLinkAndChartTitle: title
          topPusherHeight
          topPusherHeightMobile
        }
        ... on STRAPI_ComponentComponentsRichTextOnly {
          __typename
          richTextOnlyContent
          topPusherHeightMobile
          topPusherHeight
        }
        ... on STRAPI_ComponentComponentsWhoWeAreTimeline {
          __typename
          topPusherHeightMobile
          topPusherHeight
          whoWeAreTimelineItem {
            date
            title
          }
          sectionTitle {
            ytLink
            titleIcon {
              url
            }
            title
            tailwindClasses
            style
            description
          }
        }
        ... on STRAPI_ComponentComponentsTextWithTable {
          __typename
          textWithTableContent
          textWithTableTitle: title
          topPusherHeight
          topPusherHeightMobile
          showImageOnMobile
          table {
            url
          }
        }
        ... on STRAPI_ComponentComponentsOffsetImageWithList {
          __typename
          topPusherHeightMobile
          topPusherHeight
          offsetImageSubtitle: subtitle
          title
          setComponentPadding
          mobileImageHeight
          listItemImageTailwindClasses
          isReverted
          isImageVisibleOnMobile
          imageType
          imageOffset
          imageHeight
          hasLightBackground
          image {
            url
          }
          ImageWithText {
            content
            image {
              url
            }
          }
        }
        ... on STRAPI_ComponentComponentsSingleImage {
          __typename
          singleImageContent
          topPusherHeight
          topPusherHeightMobile
          image {
            url
          }
        }
        ... on STRAPI_ComponentComponentsHeader {
          __typename
          title
          textBoxCustomClasses
          subtitleImages {
            title
            iconLink
            icon {
              url
            }
          }
          headerSubtitle: subtitle
          secondaryImage {
            url
          }
          scrollButton {
            label
            link
          }
          primaryImage {
            url
          }
          mobileContentOffset
          lottieData
          contentOffset
          contactForm {
            privacyPolicyFile {
              url
            }
            nameAndSurnameLabel
            messageLabel
            formTitle
            eMailAddressLabel
            dropdownLabel
            dropdownContent
            checkboxLabel
            buttonLabel
          }
          backgroundVideo {
            url
          }
          backgroundTopOffset
          backgroundImageHeight
          backgroundImage {
            url
          }
          ImagesWithScroll {
            Title
            logos {
              url
            }
            mobileImages {
              url
            }
          }
        }
        ... on STRAPI_ComponentComponentsSectionWithSquare {
          __typename
          topPusherHeightMobile
          topPusherHeight
          squareTitle
          squareItem {
            squareItemContent
          }
          mainTitle
          description
          companyDataLabel
          companyData
        }
        ... on STRAPI_ComponentComponentsBenefitsWithTable {
          __typename
          benefitList {
            title
            description
            image {
              url
            }
          }
          scrollbarColor
          sectionTitle {
            description
            style
            tailwindClasses
            title
            titleIcon {
              url
            }
            ytLink
          }
          table {
            tableLabel
            tableImage {
              url
            }
            labelImage {
              url
            }
          }
          topPusherHeight
          topPusherHeightMobile
        }
        ... on STRAPI_ComponentComponentsTheyTrustUs {
          __typename
          topPusherHeightMobile
          topPusherHeight
          theyTrustUsScrollColor
          sectionTitle {
            ytLink
            titleIcon {
              url
            }
            title
            tailwindClasses
            style
            description
          }
          mobileImages {
            url
          }
          linesVisible
          images {
            url
          }
        }
        ... on STRAPI_ComponentComponentsCardsWithDarkBackground {
          __typename
          topPusherHeightMobile
          topPusherHeight
          sectionTitle {
            ytLink
            titleIcon {
              url
            }
            title
            tailwindClasses
            style
            description
          }
          cardWithDarkBackgroundScrollColor
          WhySolutionIsUniqueCard {
            image {
              url
            }
            subtitle
            title
          }
        }
        ... on STRAPI_ComponentComponentsWhySolutionIsUniqueCard {
          __typename
          subtitle
          title
          image {
            url
          }
        }
        ... on STRAPI_ComponentComponentsAboutFinancial {
          __typename
          topPusherHeight
          topPusherHeightMobile
          sectionTitle {
            description
            style
            tailwindClasses
            title
            ytLink
            titleIcon {
              url
            }
          }
        }
        ... on STRAPI_ComponentComponentsServicesTable {
          __typename
          topPusherHeightMobile
          topPusherHeight
          service {
            bottomText
            servicesData
            tailwindClasses
            title
          }
          sectionTitle {
            ytLink
            titleIcon {
              url
            }
            title
            tailwindClasses
            style
            description
          }
        }
        ... on STRAPI_ComponentComponentsLinksSection {
          __typename
          title
          linksSectionLink {
            title
            link
          }
        }
        ... on STRAPI_ComponentWhitePaperSingaporeSupport {
          __typename
          upperText
          image {
            url
          }
        }
        ... on STRAPI_ComponentWhitePaperDownloadForm {
          __typename
          buttonLabel
          companyLabel
          elementId
          emailAddressLabel
          formTitle
          nameAndSurnameLabel
          privacyPolicyLabel
          sideImage {
            url
          }
          topPusherHeight
          topPusherHeightMobile
        }
        ... on STRAPI_ComponentWhitePaperTripleOffices {
          __typename
          warsawOffice {
            officesData
            title
          }
          topPusherHeightMobile
          topPusherHeight
          singaporeOffice {
            officeData
            title
            vkImage {
              url
            }
          }
          sectionTitle {
            ytLink
            titleIcon {
              url
            }
            title
            tailwindClasses
            style
            description
          }
          londonOffice {
            officesData
            title
          }
          images {
            url
          }
        }
        ... on STRAPI_ComponentWhitePaperBottomLogosWithLine {
          __typename
          topPusherHeight
          topPusherHeightMobile
          logos {
            url
          }
        }
        ... on STRAPI_ComponentCaseStudiesRepeatableRichText {
          __typename
          topPusherHeightMobile
          topPusherHeight
          sectionTitle {
            ytLink
            titleIcon {
              url
            }
            title
            tailwindClasses
            style
            description
          }
          richText {
            titleIcon {
              url
            }
            title
            mobileContent
            content
          }
        }
        ... on STRAPI_ComponentCaseStudiesCaseStudyIntro {
          __typename
          topPusherHeightMobile
          topPusherHeight
          titleIcon {
            url
          }
          caseStudyIntroTitle: title
          logo {
            url
          }
          listItemUnderLogo {
            title
            link
            icon {
              url
            }
          }
          introContent
        }
        ... on STRAPI_ComponentCaseStudiesItemsWithBigText {
          __typename
          itemsWithBigTextScrollColor
          topPusherHeight
          topPusherHeightMobile
          bigTextItem {
            itemContent
          }
        }
        ... on STRAPI_ComponentCaseStudiesRichTextWithSideImage {
          __typename
          topPusherHeightMobile
          topPusherHeight
          sideImage {
            url
          }
          sectionTitle {
            ytLink
            titleIcon {
              url
            }
            title
            tailwindClasses
            style
            description
          }
          richTextContent
        }
        ... on STRAPI_ComponentCaseStudiesColumnsWithBorders {
          __typename
          textUnderColumns
          topPusherHeight
          topPusherHeightMobile
          sectionTitle {
            ytLink
            titleIcon {
              url
            }
            title
            tailwindClasses
            style
            description
          }
          mobileScrollColor
          columnWithOffsetImage {
            description
            image {
              url
            }
            title
          }
        }
        ... on STRAPI_ComponentOurTeamListOurTeamList {
          __typename
          bioButtonLabel
          topPusherHeightMobile
          topPusherHeight
          sectionTitle {
            description
            style
            tailwindClasses
            title
            ytLink
            titleIcon {
              url
            }
          }
          scrollColor
          newTeammateTitle
          newTeammateButtonLabel
          newTeammateSubtitle
          employees {
            employee {
              bio
              city
              image {
                url
              }
              isFromBoard
              nameAndSurname
              socials {
                hrefContent
                icon {
                  url
                }
              }
              title
            }
          }
        }
        ... on STRAPI_ComponentEmployeesListBoardList {
          __typename
          topPusherHeight
          topPusherHeightMobile
          sectionTitle {
            ytLink
            titleIcon {
              url
            }
            title
            tailwindClasses
            style
            description
          }
          employee {
            employee {
              bio
              city
              image {
                url
              }
              published_at
              isFromBoard
              nameAndSurname
              title
              socials {
                icon {
                  url
                }
                hrefContent
              }
            }
          }
        }
        ... on STRAPI_ComponentCardWithBackgroundImageCardWithBackgroundImage {
          __typename
          topPusherHeightMobile
          topPusherHeight
          cardWithBackgroundImageTitle: title
          cardWithBackgroundImageSubtitle: subtitle
          icon {
            url
          }
          backgroundImage {
            url
          }
        }
        ... on STRAPI_ComponentTimelineTimeline {
          __typename
          topPusherHeightMobile
          topPusherHeight
          timelineAnimationData
          sectionTitle {
            ytLink
            titleIcon {
              url
            }
            title
            tailwindClasses
            style
            description
          }
          mobileTimelineImage {
            url
          }
          timelineContent
        }
        ... on STRAPI_ComponentSplitBackgroundSplitBackgroun {
          __typename
          bottomItems
          topPusherHeight
          topPusherHeightMobile
          rightSide {
            title
            images {
              url
            }
            imageData
            description
          }
          leftSide {
            content
            title
          }
        }
        ... on STRAPI_ComponentRegisterForDemoFormRegisterForDemoForm {
          __typename
          registerForDemoAndroidAppLabel
          registerForDemoAppIdInputLabel
          registerForDemoButtonLabel
          registerForDemoCompanyLabel
          registerForDemoCountryLabel
          registerForDemoEmailLabel
          registerForDemoFormSubtitle
          registerForDemoFormTitle
          registerForDemoIosAppLabel
          registerForDemoPrivacyPolicyCheckboxLabel
          registerForDemoMobileNumberLabel
          registerForDemoTermsOfServiceCheckboxLabel
          regsiterForDemoNameAndSurnameLabel
          topPusherHeight
          topPusherHeightMobile
          sideImage {
            url
          }
        }
      }
    }
  }
}
`;

const CustomPageTemplate = (props) => {
    const components = props.data.cms.pages[0]?.components || null;

    if (!components) {
        if (typeof window !== `undefined`) {
            navigate(`${props.pageContext.locale === `pl` ? `/pl` : ``}/404`);
        }
        return null;
    }

    return (
        <Layout location={props.location} locale={props.pageContext.locale}>
            <FloatingButton navigateToForm={props.data.cms.pages[0]?.navigateToForm} />
            <DynamicZone components={components} />
        </Layout>
    );
};

export default CustomPageTemplate;
